import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { NetworkStateContextType } from 'contexts/NetworkState/context'
import { Link } from 'gatsby'

const styles = require('./NetworkStatePromptContent.css')

export const NetworkStatePromptContent = (props: {
  networkState: NetworkStateContextType;
}) => {
  const { online, requestHasTimedOut, voluntaryOffline, networkState } = props.networkState
  if(networkState && !networkState.online) {
    return (
      <Box textAlign="center" className={styles.root}>
        <Typography variant="caption" paragraph>
          You have no network connection. We will try to reconnect when a reliable network connection is detected.
        </Typography>
        <Typography variant="caption" paragraph>
          <Link to="/help/offline-mode">What is Offline Mode?</Link>
        </Typography>
      </Box>)
  }
  if(!online || voluntaryOffline) {
    return (
      <Box className={styles.root}>
        <Typography variant="caption" paragraph>
          You are now in offline mode. Remember to go back online when you have more reliable network service or the work you do while offline could be lost.
        </Typography>
        <Typography variant="caption">
          <Link to="/help/offline-mode">What is Offline Mode?</Link>
        </Typography>
      </Box>
    )
  }
  if(requestHasTimedOut) {
    return (
      <Box className={styles.root}>
        <Typography variant="caption" paragraph>
          Something is taking too long. Do you want to try to go offline?
        </Typography>
        <Typography variant="caption">
          <Link to="/help/offline-mode">What is Offline Mode?</Link>
        </Typography>
      </Box>
    )
  }

  return (<Typography variant="caption">You're online and all is well! 👌</Typography>)
}
