import React, { FC, HTMLProps } from 'react'
import clsx from 'clsx'

const styles = require('./PaddedSection.css')

export const PaddedSection: FC<HTMLProps<HTMLElement>> = (props: HTMLProps<HTMLElement>) => {
  const {
    className,
    ...passedProps
  } = props
  return (
    <section className={clsx(styles.root, className)} {...passedProps} />
  )
}
