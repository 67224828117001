import React from 'react'

import { Transaction, Transactions } from 'idb/Transactions'

export type ITransactionsDbContext = {
  instance?: Transactions
  count: number
  pending: Transaction[]
}

export const defaultContext: ITransactionsDbContext = {
  instance: undefined,
  count: 0,
  pending: [],
}

export default React.createContext<ITransactionsDbContext>(defaultContext)
