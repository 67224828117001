import React, { HTMLAttributes } from 'react'
import { motion, MotionProps } from 'framer-motion'

const styles = require('./AppHeader.css')

export const ActionLeftMotion = (props: HTMLAttributes<HTMLSpanElement> & MotionProps) => (
  <motion.span
    {...props}
    className={styles.actionLeftMotion}
    initial={{ opacity: 0, x: '-120%', y: '-50%' }}
    animate={{ opacity: 1, x: 0, y: '-50%' }}
    exit={{ opacity: 0, x: '-120%' }}
  />
)
