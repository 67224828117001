import { DependencyList, useCallback, useMemo } from 'react'

import useSessionStorage from 'hooks/useSessionStorage'

export const useTimeSince = (key: string, dependencies: DependencyList = []): [number | null, () => void] => {
  const [lastDateStr, setLastDateStr] = useSessionStorage<string>(key, undefined, true)
  const setLastDate = useCallback(() => {
    setLastDateStr(new Date().toISOString())
  }, [setLastDateStr])

  const lastDate: Date | undefined = useMemo(() => {
    if(lastDateStr) {
      const d = new Date(lastDateStr)
      if(!isNaN(d.getTime())) {
        return d
      }
    }
    return undefined
  }, [lastDateStr])

  const timeSince: number | null = useMemo(() => {
    if(!lastDate) {
      return null
    }
    return Date.now() - lastDate.getTime()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastDate, ...dependencies])

  return [timeSince, setLastDate]
}

export default useTimeSince
