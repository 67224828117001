import React, { useContext } from 'react'
import { MdSignalWifiOff } from 'react-icons/md'
import { Badge } from '@material-ui/core'
import transactionsDbContext from 'contexts/Transactions/transactionsDbContext'

type Props = {
  className?: string
}

export const OfflineIndicator = ({ className }: Props) => {
  const { count } = useContext(transactionsDbContext)

  return (
    <label style={{ marginBottom: 0 }} className={className}>
      <Badge badgeContent={count} color="primary" style={{ paddingRight: '0.25em' }}>
        <MdSignalWifiOff />
      </Badge>
    </label>
  )
}
