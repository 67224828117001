import React, { MutableRefObject, ReactElement, ReactNode, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { MdDragHandle } from 'react-icons/md'
import { useSwipeable } from 'react-swipeable'
import { Box, Button, ClickAwayListener, Grid } from '@material-ui/core'
import clsx from 'clsx'
import { networkStateContext } from 'contexts/NetworkState'
import { AnimatePresence, motion } from 'framer-motion'

import { useElementDimensions } from 'hooks/useElementDimensions'
import { useTimeout } from 'hooks/useTimeout'
import useTimeSince from 'hooks/useTimeSince'

import { ActionLeftMotion } from './ActionLeftMotion'
import { NetworkStatePromptContent } from './NetworkStatePromptContent'
import { OfflineIndicator } from './OfflineIndicator'

const styles = require('./AppHeader.css')

export interface Props {
  title?: string
  subtitle?: ReactNode
  actionLeft?: ReactElement
  actionRight?: ReactElement
  fixed?: boolean
  getLayoutParent?: 'parent' | ((ref: MutableRefObject<HTMLElement | null>) => HTMLElement | null)
  alwaysAllowExpand?: boolean
  maxNotificationMs?: number
  peekBorderHeight?: number
}

export const AppHeader = (props: (Props & React.DOMAttributes<HTMLElement>)) => {
  const {
    title,
    subtitle,
    actionLeft,
    actionRight = <span />,
    fixed,
    getLayoutParent,
    alwaysAllowExpand = false,
    maxNotificationMs = 1000 * 60 * 2,
    peekBorderHeight = 5,
  } = props
  const headerRef = useRef<HTMLElement | null>(null)
  const headerRect = useElementDimensions(headerRef)
  const netState = useContext(networkStateContext)
  const {
    online,
    networkState,
    requestHasTimedOut,
    voluntaryOffline,
    setVoluntaryOffline,
  } = netState
  const [expanded, setExpanded] = useState<boolean>(false)
  const [timeSinceLastNotification, setLastNotified] = useTimeSince('lastNotified_networkTimeout', [online, requestHasTimedOut, voluntaryOffline])

  const isExpandable = useMemo<boolean>(() => {
    return (alwaysAllowExpand || !online || requestHasTimedOut || voluntaryOffline)
  }, [alwaysAllowExpand, online, requestHasTimedOut, voluntaryOffline])

  const showExpandHint = useMemo<boolean>(() => {
    return (!online || requestHasTimedOut || voluntaryOffline)
  }, [online, requestHasTimedOut, voluntaryOffline])

  const getLayoutParentFn = useMemo(() => {
    if(typeof getLayoutParent === 'function') return getLayoutParent
    if(getLayoutParent === 'parent') {
      return (ref: MutableRefObject<HTMLElement | null>): (HTMLElement | null) => {
        return ref && ref.current && ref.current.parentElement
      }
    }
    return (ref: MutableRefObject<HTMLElement | null>): (HTMLElement | null) => {
      return ref && ref.current && ref.current.closest('div') as HTMLElement
    }
  }, [getLayoutParent])

  useLayoutEffect(() => {
    const parent = getLayoutParentFn(headerRef)
    if(fixed && parent && headerRect && headerRect.height && headerRect.height > 0) {
      parent.style.paddingTop = headerRect.height + 'px'
    }
    return () => {
      if(fixed && parent) {
        parent.style.paddingTop = 'unset'
      }
    }
  }, [fixed, getLayoutParentFn, headerRect])

  const notificationTimeout = useMemo<number | null>(() => {
    if(isExpandable && (!timeSinceLastNotification || (timeSinceLastNotification && timeSinceLastNotification > maxNotificationMs))) {
      return 350
    }
    return null
  }, [isExpandable, maxNotificationMs, timeSinceLastNotification])

  useTimeout(() => {
    setExpanded(true)
    setLastNotified()
  }, notificationTimeout)

  // reset expanded if no longer expandable
  useEffect(() => {
    if(!isExpandable) {
      setExpanded(false)
    }
  }, [isExpandable])

  const handleOfflineClick = () => {
    setVoluntaryOffline(!voluntaryOffline)
  }

  const handleClickAway = () => {
    setExpanded(false)
  }

  const toggleExpanded = () => {
    if(expanded) {
      setExpanded(false)
    } else {
      if(isExpandable) {
        setExpanded(true)
      }
    }
  }

  const handleMainRowClick = () => {
    toggleExpanded()
  }

  const swipeHandlers = useSwipeable({
    onSwipedDown: () => {
      if(isExpandable) {
        setExpanded(true)
      }
    },
    onSwipedUp: () => {
      setExpanded(false)
    },
  })
  // store the returned ref from useSwipeable for use next
  const swipeRefCb = swipeHandlers.ref
  /**
   * Normally, we would just pass `headerRef` as the `ref` prop to the header
   * element. However, because our component and the `react-swipeable` hook
   * *both* depend on the ref, we have to add a step to combine them.
   */
  swipeHandlers.ref = useCallback((elem: HTMLElement | null) => {
    if(elem) {
      headerRef.current = elem
    }
    // call the swipeable ref so it can attach its event listeners
    swipeRefCb(elem)
  }, [swipeRefCb])

  return (
    <ClickAwayListener onClickAway={handleClickAway} touchEvent="onTouchStart">
      <header
        {...swipeHandlers}
        className={clsx(
          styles.root,
          {
            [styles.offline]: !online,
            [styles.timedout]: online && requestHasTimedOut,
            [styles.expanded]: expanded,
            [styles.fixed]: fixed,
          }
        )}
      >
        <AnimatePresence initial={false}>
          {isExpandable ? (
            <motion.div
              className={clsx(styles.drawerContent, styles.collapsible)}
              initial={{ maxHeight: 0 }}
              animate={{ height: 'auto', minHeight: showExpandHint ? peekBorderHeight : 0, maxHeight: 300 }}
              exit={{ height: 0, minHeight: 0, maxHeight: 0 }}
              transition={{ stiffness: 150 }}
              key="drawer"
            >
              <AnimatePresence>
                {expanded ? (
                  <motion.div
                    className={clsx(styles.collapsible)}
                    initial={{ height: 0 }}
                    animate={{ height: '100%' }}
                    exit={{ height: 0 }}
                    transition={{ damping: 50 }}
                    key="offlinePrompt"
                  >
                    <Box p={2}>
                      <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                        spacing={2}
                        wrap="nowrap"
                      >
                        <Grid item xs>
                          <NetworkStatePromptContent networkState={netState} />
                        </Grid>
                        {networkState && networkState.online ? (
                          <Grid item>
                            <Button
                              onClick={handleOfflineClick}
                              variant="contained"
                              size="small"
                              color="default"
                            >
                              {!voluntaryOffline ? (
                                <>Go&nbsp;Offline</>
                              ) : (
                                <>Go&nbsp;Online</>
                              )}
                            </Button>
                          </Grid>
                        ) : null}
                      </Grid>
                    </Box>
                  </motion.div>
                ) : null}
              </AnimatePresence>
            </motion.div>
          ) : null}
          {showExpandHint && (
            <motion.div
              className={styles.expandableNotifier}
              onClick={handleMainRowClick}
              initial={{ y: 0, x: '-50%', opacity: 0 }}
              animate={{ y: '100%', x: '-50%', opacity: 1 }}
              exit={{ y: 0, opacity: 0 }}
              key="expandableNotifier"
            >
              <MdDragHandle />
            </motion.div>
          )}
        </AnimatePresence>
        <div
          onClick={handleMainRowClick}
          className={styles.mainRow}
        >
          <span className={clsx(styles.flexChild, styles.leftPresenceWrapper)}>
            <AnimatePresence>
              {actionLeft ? (
                <ActionLeftMotion key="action">
                  {actionLeft}
                </ActionLeftMotion>
              ) : !online ? (
                <ActionLeftMotion key="offline">
                  <OfflineIndicator className={styles.offlineIndicator} />
                </ActionLeftMotion>
              ) : <ActionLeftMotion key="placeholder" />}
            </AnimatePresence>
          </span>
          <div className={styles.headingWrapper}>
            <h1 className={styles.heading} role="heading" aria-level={1}>
              {title}
            </h1>
            {subtitle && (<h2 className={styles.subtitle}>{subtitle}</h2>)}
          </div>
          <span className={clsx(styles.flexChild, styles.rightPresenceWrapper)}>
            {actionRight}
          </span>
        </div>
      </header>
    </ClickAwayListener>
  )
}

export default AppHeader
